export const footerData = [
  {
    title: "Ayykori",
    items: [
      { name: "About Ayykori", anchorTag: false, link: "/" },
      { name: "Privacy policy", anchorTag: false, link: "/privacy-policy" },
      {
        name: "Terms & Conditions",
        anchorTag: false,
        link: "/terms-and-conditions",
      },
    ],
  },
  {
    title: "Grab The Features",
    items: [
      {
        name: "Earn With Ayykori",
        anchorTag: false,
        link: "/affiliate",
      },
      // {
      //   name: "Ayykori Online Workshop",
      //   anchorTag: false,
      //   loginNeed: true,
      //   link: "/my-account/online-workshop",
      // },

      // {
      //   name: "How can I Sign Up in Ayykori?",
      //   link: "https://blog.ayykori.com/how-to-sign-up-2/",
      // },
      // {
      //   name: "Why Should You Shop from AyyKori?",
      //   link: "https://blog.ayykori.com/how-long-will-it-take-to-receive-the-money-earned-from-the-promotion",
      // },
      // {
      //   name: "Get Cashbacks",
      //   anchorTag: false,
      //   link: "/",
      // },
    ],
  },
  {
    title: "Grab The Features",
    items: [
      {
        name: "Affiliate Masterclass ",
        anchorTag: false,
        // loginNeed: true,
        link: "/affiliate/affiliate-marketing-masterClass-program",
      },
      {
        name: "Area Ambassador",
        anchorTag: false,
        // loginNeed: true,
        link: "/affiliate/area-ambassador",
      },
      {
        name: "Brand Partnership",
        anchorTag: false,
        // loginNeed: true,
        link: "/affiliate/brand-partnership",
      },

      {
        name: "Campus Ambassador ",
        anchorTag: false,
        // loginNeed: true,
        link: "/affiliate/campus-ambassador",
      },
      // {
      //   name: "How can I Sign Up in Ayykori?",
      //   link: "https://blog.ayykori.com/how-to-sign-up-2/",
      // },
      // {
      //   name: "Why Should You Shop from AyyKori?",
      //   link: "https://blog.ayykori.com/how-long-will-it-take-to-receive-the-money-earned-from-the-promotion",
      // },
      // {
      //   name: "Get Cashbacks",
      //   anchorTag: false,
      //   link: "/",
      // },
    ],
  },
  {
    title: "Know In Detail",
    items: [
      {
        name: "Video Tutorials",
        anchorTag: false,
        link: "/blogs/cashback/video",
      },

      { name: "FAQs", anchorTag: false, link: "/faqs" },
      { name: "Customer Support", anchorTag: false, link: "/customer-support" },
    ],
  },
];

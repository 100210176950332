import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import HighestCashBack from "../../components/Brands/TopnotchCashBack";
import WebsiteEduVideo from "../../components/EduVideo/WebsiteEduVideo";
import CashbackProduct from "../../components/Products/CashBackProduct";

import Brands from "../../components/Brands/Brands";
import Categories from "../../components/Categories/Categories";
import ShareAndJoy from "../../components/ShareJoy/ShareAndJoy";
import AfterLoginSlider from "../../components/Slider/AfterLoginSlider";
import useAuth from "../../hooks/useAuth";
import CommonLayout from "../../layout/CommonLayout";

const HomePage = () => {
  const isAuth = useAuth();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const reloadPage = useSelector((state) => state?.reload?.reload);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    setReload(reload + 1);
  }, [isLogin]);

  // useEffect(() => {}, [reloadPage]);

  /*For PWA Start - Please don't touch this code*/
  const [, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the default installation prompt
      e.preventDefault();

      // Store the deferred prompt for later use
      setDeferredPrompt(e);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Bangladesh's First Cashback & Affiliate Marketplace | Join Free & Save
          Money
        </title>
      </Helmet>
      <CommonLayout>
        {isAuth || Cookies.get("ayykori") ? (
          <AfterLoginSlider />
        ) : (
          <AfterLoginSlider />
        )}
        {/* <MobileQuickLink /> */}
        {/* <BlogCardForHome /> */}
        <WebsiteEduVideo
          newData={{ title: "Explore Tutorials", dataLimit: 20 }}
        />
        {/* 
        <div className="px-3 md:mx-14 md:mb-16">
          {campaignBanner && (
            <CampaignCard
              campaignVideo={campaignBanner[0].image}
              link={"/campaign"}
            />
          )}
        </div> */}
        {/* <Trending newData={{ title: "Premium Brands", dataLimit: 20 }} /> */}
        <HighestCashBack />
        {/* <div className="px-3 md:mx-14 md:mb-16">
          {campaignBanner && (
            <CampaignCard
              campaignVideo={campaignBanner[1].image}
              link={"/campaign"}
            />
          )}
        </div> */}

        <Categories />
        <Brands />
        <CashbackProduct />
        <ShareAndJoy />
        {/* <Suspense fallback={<div>Loading ShareAndJoy...</div>}>
          <ShareAndJoy />
        </Suspense> */}

        {/* For SEO */}
        <div className="px-3 md:px-16 pb-5 hidden ">
          <h4 className="text-lg font-semibold">About Ayykori</h4>
          <p>
            Ayykori, the first Bangladeshi cashback site, offers users a unique
            opportunity to save money and earn extra income. With cashback on
            purchases, users can save on their total shopping expenses. Ayykori
            also provides a flexible earning model for housewives, unemployed
            individuals, and university students to work from home and earn
            money without any investment required. The signup process is quick
            and easy, with payments twice a month directly to users' bank
            accounts or mobile financial service accounts. Ayykori stands out
            with its referral program, allowing users to earn money by sharing
            brand deals with friends and family. With its innovative approach to
            savings and earnings, Ayykori is a convenient platform for those
            looking to save money or earn extra income. Join Ayykori now and
            experience the power of cashback and referral bonuses for maximum
            savings and earnings! Ayykori, the first Bangladeshi cashback site,
            offers users a unique opportunity to save money and earn extra
            income. With cashback on purchases, users can save on their total
            shopping expenses. Ayykori also provides a flexible earning model
            for housewives, unemployed individuals, and university students to
            work from home and earn money without any investment required. The
            signup process is quick and easy, with payments twice a month
            directly to users' bank accounts or mobile financial service
            accounts. Ayykori stands out with its referral program, allowing
            users to earn money by sharing brand deals with friends and family.
            With its innovative approach to savings and earnings, Ayykori is a
            convenient platform for those looking to save money or earn extra
            income. Join Ayykori now and experience the power of cashback and
            referral bonuses for maximum savings and earnings! Ayykori, the
            first Bangladeshi cashback site, offers users a unique opportunity
            to save money and earn extra income. With cashback on purchases,
            users can save on their total shopping expenses. Ayykori also
            provides a flexible earning model for housewives, unemployed
            individuals, and university students to work from home and earn
            money without any investment required. The signup process is quick
            and easy, with payments twice a month directly to users' bank
            accounts or mobile financial service accounts. Ayykori stands out
            with its referral program, allowing users to earn money by sharing
            brand deals with friends and family. With its innovative approach to
            savings and earnings, Ayykori is a convenient platform for those
            looking to save money or earn extra income. Join Ayykori now and
            experience the power of cashback and referral bonuses for maximum
            savings and earnings!Ayykori, the first Bangladeshi cashback site,
            offers users a unique opportunity to save money and earn extra
            income. With cashback on purchases, users can save on their total
            shopping expenses. Ayykori also provides a flexible earning model
            for housewives, unemployed individuals, and university students to
            work from home and earn money without any investment required. The
            signup process is quick and easy, with payments twice a month
            directly to users' bank accounts or mobile financial service
            accounts. Ayykori stands out with its referral program, allowing
            users to earn money by sharing brand deals with friends and family.
            With its innovative approach to savings and earnings, Ayykori is a
            convenient platform for those looking to save money or earn extra
            income. Join Ayykori now and experience the power of cashback and
            referral bonuses for maximum savings and earnings!
          </p>
        </div>
      </CommonLayout>
    </>
  );
};

export default HomePage;

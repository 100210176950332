import React from "react";

const DarazOrderDelayNotice = () => {
  return (
    <div className=" text-base  flex items-start gap-2">
      <span className=" text-secondary text-xl font-medium">Note:</span>
      <p className="text-gray-500 ">
        Due to Daraz's new server update issue, Dashboard will be updated after
        one week. No order track will be lost, every order will be displayed in
        the dashboard soon.
      </p>
    </div>
  );
};

export default DarazOrderDelayNotice;

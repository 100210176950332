export const cashbackStepperData = [
  {
    brandDomain: "esquireelectronicsltd.com",
    brandUnid: "2003",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Esquire Electronics website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "ihwbd.com",
    brandUnid: "2016",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to International Homeware website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: " Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "organiconline.com.bd",
    brandUnid: "2017",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "  Step 2: AyyKori will take you to Organic Online website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "dazzyonline.com",
    brandUnid: "2025",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "  Step 2: AyyKori will take you to Dazzy Online website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "ribana.com.bd",
    brandUnid: "2075",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "  Step 2: AyyKori will take you to Ribana website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "olabbd.com",
    brandUnid: "2027",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "  Step 2: AyyKori will take you to OLAB BD website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "pinkflashbd.com",
    brandUnid: "2010",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to Pink Flash BD website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "greengrocery.com.bd",
    brandUnid: "2030",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to Green Grocery website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "medeasy.health",
    brandUnid: "2031",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to MedEasy website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandDomain: "daraz.com.bd",
    brandUnid: "2001",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to Daraz website or App. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2029",
    brandDomain: "in2travels.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: " Step 2: AyyKori will take you to In2Travel website. Now purchase anything you desire and pay however you want.        ",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2033",
    brandDomain: "bacchakaccha.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Baccha Kaccha website. Now purchase anything you desire and pay however you want.  ",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2034",
    brandDomain: "khaasfood.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Khaas Food website. Now purchase anything you desire and pay however you want. ",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2012",
    brandDomain: "oneummahbd.shop",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to One Ummah BD website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2037",
    brandDomain: "beauti4me.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Beauty4Me website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2036",
    brandDomain: "asklegalx.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to LegalX website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2038",
    brandDomain: "aalaadin.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Aalaadin website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2039",
    brandDomain: "learning.uvtrbd.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to UVTR Learning website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2039",
    brandDomain: "learning.uvtrbd.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to UVTR Learning website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2040",
    brandDomain: "shohoz.com/bus-tickets",
    steps: [
      {
        step: "Step 1: Click on 'Avail Cashback'. AyyKori will take you to Shohoz Bus website. Do not move from that page.",
      },
      {
        step: "Step 2: Now purchase bus ticket as you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2041",
    brandDomain: "stygen.gift",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Stygen website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2042",
    brandDomain: "brandboxbd.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Brandbox website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2020",
    brandDomain: "deencommerce.com",
    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Deen website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "   Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2055",
    brandDomain: "bestelectronics.com.bd",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Best Electronics website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: " Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2056",
    brandDomain: "ostad.app",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Ostad website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2057",
    brandDomain: "hoco.com.bd",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Hoco BD website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2044",
    brandDomain: "othoba.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Othoba website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: " Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: " Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2045",
    brandDomain: "othoba.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Othoba website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2058",
    brandDomain: "vistabangladesh.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Vista website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: " Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2043",
    brandDomain: "proteinmarket.com.bd",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Protein Market website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2059",
    brandDomain: "goofiworld.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Goofi World website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2060",
    brandDomain: "bazar365.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Bazar365 website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2065",
    brandDomain: "bimafy.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Bimafy website. Now purchase any policy you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2061",
    brandDomain: "shikkha.xyz",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Shikkha website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2062",
    brandDomain: "amaderacademy.com",

    steps: [
      { step: "Step 1: Click on 'Avail Cashback'" },
      {
        step: "Step 2: AyyKori will take you to Amader Academy website. Now purchase anything you desire and pay however you want.",
      },
      {
        step: "Step 3: After purchasing, come back to AyyKori's Dashboard to check your Cashback Balance.",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account.",
      },
    ],
  },
  {
    brandUnid: "2063",
    brandDomain: "chaldal.com",
    steps: [
      { step: "Step 1: Purchase any amount of groceries from Chaldal" },
      {
        step: "Step 2: Open your AyyKori App or Website. Find Chaldal and click on Get Cashback.",
      },
      {
        step: "Step 3: Now click on Avail Cashback and fill up the form with your Order ID, Order Date, Order Amount and Screenshot of your Order Details page",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account (Max 30 Days)",
      },
    ],
  },
  {
    brandUnid: "2066",
    brandDomain: "keeron.com",

    steps: [
      { step: "Step 1: Purchase any course from Keeron" },
      {
        step: "Step 2: Open your AyyKori App or Website. Find Keeron and click on Get Cashback.",
      },
      {
        step: "Step 3: Now click on Avail Cashback and fill up the form with your Course Name, Order Date, Order Amount and Screenshot of your Order Details page",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account (Max 30 Days)",
      },
    ],
  },
  {
    brandUnid: "2064",
    brandDomain: "pickaboo.com",

    steps: [
      { step: "Step 1: Purchase any product from Pickaboo" },
      {
        step: "Step 2: Open your AyyKori App or Website. Find Pickaboo and click on Get Cashback.",
      },
      {
        step: "Step 3: Now click on Avail Cashback and fill up the form with your Order ID, Order Date, Order Amount and Screenshot of your Order Details page",
      },
      {
        step: "Step 4: We will send Cashback to your bKash, Nagad or Bank Account (Max 30 Days)",
      },
    ],
  },
];
